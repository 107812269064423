<template>
  <div class="self-center">
    <b-badge
      v-if="params.approved === true || params.approved === undefined"
      pill
      class="h-6"
      variant="success"
    >
      Approved
    </b-badge>
    <b-badge
      v-if="params.approved === null"
      pill
      class="h-6"
      variant="warning"
    >
      Pending
    </b-badge>
    <b-badge
      v-if="params.approved === false"
      pill
      class="h-6"
      variant="danger"
    >
      Rejected
    </b-badge>
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererStatus',
  props: {
    params: Object,
  },
  components: {
    BBadge,
  },
  created() {

  },
}
</script>
