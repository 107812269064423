<template>
  <div class="d-flex">
    {{ params.cleaned_start_time }} - {{ params.cleaned_end_time }}
  </div>

</template>

<script>
export default {
  name: 'CellRendererEventTime',
  props: {
    params: Object,
  },

}
</script>
