<template>
  <section class="h-100 m-0 p-0">
    <!-- Header -->
    <b-card
      :style="`height: ${heightVariable.header};`"
      class="my-0 p-0"
    >
      <b-row class="h-100">
        <!-- Avatar & Name -->
        <b-col cols="8" sm="6" class="h-100 my-auto">
          <div class="w-100 h-100 d-flex align-items-center">
            <div class="w-100 my-auto">
              <b-row class="w-100 mx-1">
                <h5
                  class="m-0 p-0 font-weight-bolder cursor-pointer"
                >
                  Help Desk
                </h5>
              </b-row>

              <!-- <b-row class="w-100 mx-1">
                <h5
                  class="m-0 p-0 font-weight-bolder cursor-pointer"
                >
                  online
                </h5>
              </b-row> -->
            </div>
          </div>
        </b-col>

        <b-col>
          <!-- <b-button
            variant="success"
            @click="chatWithAgent"
          >
            Chat with Agent
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>

    <!-- Chat Logs -->
    <!-- <template v-if="issue.contact_method === 'telegram'">
      <div
        ref="chatLogsDiv"
        :style="`height: ${heightVariable.chatLogs};`"
        class="chatBox-container"
      >
        <chat-logs-telegram :issue="issue" />
      </div>
    </template> -->

    <template>
      <div
        v-if="!$isEmpty(issue.assigned_to) || (!$isEmpty(issueChats) && !issue.unresolvable_by_bot)"
        ref="chatLogsDiv"
        :style="`height: ${heightVariable.chatLogs};`"
        class="chatBox-container"
      >
        <b-overlay
          :show="!issueChats.length > 0"
          rounded="sm"
          class="h-100"
        >
          <ChatLogs
            :chats="issueChats"
            :sender="sender"
            :recipient="recipient"
            :issue="issue"
            @chat-with-agent="chatWithAgent"
          />

          <!-- <div
            v-if="!issue.unresolvable_by_bot && $isEmpty(issue.assigned_to) && issue.status !== 'closed'" 
            class="mt-3"
          >
            <b-button
              variant="success"
              @click="chatWithAgent"
            >
              Chat with Agent
            </b-button>
          </div> -->
        </b-overlay>
      </div>

      <div
        v-else
        :style="`height: ${heightVariable.chatLogs};`"
      >
        <div class="d-flex align-items-center h-100">
          <b-row class="w-100 text-center">
            <template v-if="$store.state.auth.ActiveUser.email_address === issue.user_id && $isEmpty(issue.assigned_to) && !$isEmpty(currentQueueMeta)">
              <b-col
                v-if="!$isEmpty(currentQueueMeta.how_many_queue_ahead) && currentQueueMeta.how_many_queue_ahead > 0"
                cols="12"
                class="mt-2"
              >
                <div class="mt-2">
                  <h4>Current Queue</h4>
                  <!-- <h1 class="font-weight-bolder">{{ currentQueueMeta.current_queue }} / {{ currentQueueMeta.total_queues }}</h1> -->
                  <b-spinner v-if="currentQueueMeta.how_many_queue_ahead <= 0" class="mt-1" label="Spinning" />
                  <h1 v-else class="font-weight-bolder">{{ currentQueueMeta.how_many_queue_ahead || 1 }}</h1>
                </div>
              </b-col>

              <b-col
                v-if="currentQueueMeta.how_many_queue_ahead <= 1"
                cols="12" 
                class="mt-1"
              >
                <div class="mt-2">
                  <h6> One moment please, </h6>
                  <h6> Our agent will get to you shortly </h6>
                </div>

                <b-spinner class="mt-1" label="Spinning" />
              </b-col>
            </template>

            <template v-else-if="$store.state.auth.ActiveUser.email_address !== issue.user_id && !$isEmpty(issue._id)">
              <b-col cols="12" class="mt-2">
                <h4>
                  Send a chat to Take / Assign this issue to yourself
                </h4>
                <!-- <b-button class="mt-1" variant="primary">
                  Start Conversation
                </b-button> -->
              </b-col>
            </template>

            <template v-else>
              <div class="w-100 h-100">
                <!-- <b-spinner />  -->
              </div>
            </template>
          </b-row>
        </div>
      </div>
    </template>

    <!-- Send Chat -->
    <div
      :style="`height: ${heightVariable.sendMessage};`"
      class="bg-white d-flex flex-wrap align-item-center my-0 p-0 px-1"
    >
      <div
        v-if="!isWidget"
        class="d-flex m-0 p-0 w-100"
      >
        <div class="my-auto">
          Source: {{ issue.official_name || '-' }}
        </div>

        <!-- <div cols="3" class="d-flex align-items-center" style="width: 25%;">
          <div> {{ issue.contact_method }} </div>
          <b-dropdown
            class="w-100"
            variant="outline-dark"
            :text="issue.contact_method"
            right
          >
            <b-dropdown-item @click="issue.contact_method = 'chat'">
              <span>Chat</span>
            </b-dropdown-item>
            <b-dropdown-item @click="issue.contact_method = 'telegram'">
              <span>Telegram</span>
            </b-dropdown-item>
            <b-dropdown-item @click="issue.contact_method = 'email'">
              <span>Email</span>
            </b-dropdown-item>
          </b-dropdown>
        </div> -->
      </div>

      <div
        class="d-flex m-0 p-0 w-100"
      >
        <div
          :class="{'p-1': isWidget}"
          class="my-auto pr-1" style="width: 75%;"
        >
          <b-form-textarea
            ref="chatMessageInput"
            v-model="chat.message"
            rows="2"
            placeholder="Enter your message here"
            no-resize
            class="w-full m-0 p-0"
            :disabled="checkIfAbleToSendMessage()"
            @keydown.enter.exact.prevent="sendChat"
            @keydown.enter.shift.exact.prevent="chat.message += '\n'"
          /> 
        </div>

        <div cols="3" class="d-flex align-items-center" style="width: 25%;">
          <b-button
            variant="primary"
            class="w-100"
            :disabled="checkIfAbleToSendMessage()"
            @click="sendChat"
          >
            Send
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ChatLogs from './ChatLogs.vue'
import ChatLogsTelegram from './ChatLogsTelegram.vue'

export default {
  components: {
    ChatLogs,
    ChatLogsTelegram,
  },
  props: {
    isChatLoading: { type: Boolean, default: false },
    issue: { type: Object, default: () => {} },
    issueChats: { type: Array, default: () => [] },
    sender: { type: Object, default: () => {} },
    recipient: { type: Object, default: () => {} },
    isWidget: { type: Boolean, default: false },

    // issue queue
    currentQueue: { type: Array, default: () => [] },
    currentQueueMeta: { type: Object, default: () => {} },
  },
  data() {
    return {
      chat: {},

      heightVariable: {
        header: '12%',
        chatLogs: '70%',
        sendMessage: '18%',
      },

      refChatLogsDiv: null,
      chatLogsCount: 0, // to use when auto scrolling the chatbox

    }
  },
  created() {
    if (this.isWidget) {
      this.heightVariable = {
        header: '15%',
        chatLogs: '65%',
        sendMessage: '20%',
      }
    }
  },
  mounted() {
    this.$refs.chatMessageInput.focus()

    this.chatLogsCount = this.issueChats.length
    this.refChatLogsDiv = this.$refs.chatLogsDiv || null

    if (!this.issue.unresolvable_by_bot) {
      this.recipient = {
        fullname: 'bot',
        email: ''
      }
    }
  },
  updated() {
    this.refChatLogsDiv = this.$refs.chatLogsDiv || null

    if (this.chatLogsCount !== this.issueChats.length) {
      this.refChatLogsDiv.scrollTop = this.refChatLogsDiv.scrollHeight
    }

    this.chatLogsCount = this.issueChats.length
  },
  methods: {
    async sendChat() {
      if (this.issue.contact_method === 'telegram') {
        this.sendIssueTelegramChat()
      } else {
        this.sendIssueChat()
      }
    },
    async sendIssueTelegramChat() {
      let proceed = true
      const currentUserEmail = this.$store.state.auth.ActiveUser.email

      // Validation
      if (this.$isEmpty(this.chat.message)) { proceed = false }

      this.chat.issue_id = this.issue._id
      this.chat.chat_id = this.issue.chat_id
      this.chat.sender_id = currentUserEmail
      this.chat.sent_from = currentUserEmail
      this.chat.sent_method = 'telegram'

      this.chat.recipient_id = this.issue.user_id

      if (proceed) {
        if (currentUserEmail === this.issue.user_id && !this.$isEmpty(this.issue.assigned_to)) {
          this.chat.recipient_id = this.issue.assigned_to
        } else {
          if (this.$route.name === 'help-desks-issues-view') {
            await this.assignToMe()
          }
        }

        await this.$http
          .post(`/api/help-desks/issues-telegram-chats`, {
            form_data: this.chat,
          })
          .then(response => {
            this.$emit('refresh-chats', true)
            this.chat.message = ""
  
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Chat Sent',
                  icon: 'AlertCircleIcon',
                  text: 'Chat successfully sent',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
 
            if (this.issue.status === 'closed') {
              this.reopenIssue()
            }
          })
          .catch(() => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Something went wrong',
                  icon: 'AlertCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
        const container = this.$refs.chatLogsDiv
        container.scrollTop = container.scrollHeight
      }
    },
    async sendIssueChat() {
      let proceed = true

      // Validation
      if (this.$isEmpty(this.chat.message)) { proceed = false }

      this.chat.issue_id = this.issue._id
      this.chat.sender_id = this.$store.state.auth.ActiveUser.email
      this.chat.sent_from = this.$store.state.auth.ActiveUser.email
      this.chat.sent_method = 'chat'

      const currentUser = this.$store.state.auth.ActiveUser.email
      this.chat.recipient_id = this.issue.user_id

      if (proceed) {
        if (currentUser === this.issue.user_id && !this.$isEmpty(this.issue.assigned_to)) {
          // ! currently defaulting to the sender
          this.chat.recipient_id = this.issue.assigned_to
        } else {
          if (this.$route.name === 'help-desks-issues-view') {
            await this.assignToMe()
          }
        }

        await this.$http
          .post(`/api/help-desks/issues-chats/`, {
            form_data: this.chat,
          })
          .then(response => {
            this.$emit('refresh-chats', true)
            this.chat.message = ""
  
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Chat Sent',
                  icon: 'AlertCircleIcon',
                  text: 'Chat successfully sent',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
 
            if (this.issue.status === 'closed') {
              this.reopenIssue()
            }
          })
          .catch(() => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Something went wrong',
                  icon: 'AlertCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
        const container = this.$refs.chatLogsDiv
        container.scrollTop = container.scrollHeight
      }
    },
    assignToMe() {
      const form_data = this.issue
      form_data['assigned_to'] = this.$store.state.auth.ActiveUser.email

      this.$http
        .put(`/api/help-desks/issues/${this.issue._id}/assign`, {
          form_data,
        })
        .then(response => {
          this.$emit('refresh-issue', true)
        })
        .catch(() => {})
    },
    reopenIssue() {
      this.$http
        .put(`/api/help-desks/issues/${this.issue._id}/open`)
        .then(response => {
          this.$emit('refresh-issue', true)
        })
        .catch(() => {})
    },

    chatWithAgent() {
      if (!this.$isEmpty(this.issue._id)) {
        this.issue.unresolvable_by_bot = 'True'
        this.$http
          .put(`/api/help-desks/issues/${this.issue._id}/unresolvable-by-bot`, {
            form_data: this.issue,
          })
          .then(response => {
            // console.log(`🚀 ~ file: ChatBox.vue ~ line 316 ~ chatWithAgent ~ response`, response)
          })
          .catch(() => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Something went wrong',
                  icon: 'AlertCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
      }
    },
    sendAnswerToBot(answer) {
      this.chat.message = answer
      this.sendChat()
    },
    checkIfAbleToSendMessage() {
      if (this.$isEmpty(this.issue._id)) {
        return true
      }

      if ((this.$isEmpty(this.issue.assigned_to)) && (this.issue.user_id === this.$store.state.auth.ActiveUser.email)) {
        if (!this.issue.unresolvable_by_bot) {
          return false
        }
        return true
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.chatBox-container {
  overflow-y: scroll;
  overflow-x: none;
}

.chats {
  .chat-avatar {
    float: right;
  }
  .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
    .chat-content {
      float: right;
      padding: 0.7rem 1rem;
      margin: 0 1rem 10px 0;
      clear: both;
      color: $primary;
      // @include gradient-directional(map-get($primary, 'base'), map-get($primary, 'lighten-2'), 80deg);
      border-radius: 12px;
      box-shadow: 0 4px 8px 0 rgba($black, 0.12);
      max-width: 75%;
      p {
        margin: 0;
      }
    }
  }
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 10px 1rem;
        color: $white;
        background: none;
        background-color: lighten($white, 18%);
      }
    }
  }
}
</style>
