<template>
  <div class="d-d-flex justify-content-center">
    <div class="whitespace-no-wrap text-center">

      <b-button
        v-if="params.total_pending_jobs > 0"
        v-b-tooltip.hover.top="'Total number of jobs pending approval. Click to view more.'"
        :to="'/events-manager/pending-jobs/' + params.event_id"
        :variant="params.total_pending_jobs > 0 ? 'success' : 'primary'"
      >{{ params.total_pending_jobs }}</b-button>

      <b-button
        v-else
        variant="primary"
      >
        0
      </b-button>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormRadio,
  BButton,
  BRow,
  BModal,
  VBTooltip,
  BAlert,
  BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererPendingJobs',
  components: {
    BCard,
    BFormRadio,
    BFormInput,
    BButton,
    BRow,
    BModal,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    params: Object,
  },
  computed: {
    url() {
      return '/apps/user/user-view/268'

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.id
    },
  },
}
</script>
