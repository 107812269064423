<template>

  <div class="self-center">

    <b-badge
      v-if="params.active"
      pill
      class="h-6"
      variant="success"
    >
      Live
    </b-badge>
    <b-badge
      v-else
      pill
      class="h-6"
      variant="danger"
    >
      Closed
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  components: {
  },
  props: {
    params: Object,
  },
  created() {
  },
}
</script>
